<template>
  <div class="pb-lg-15">
    <div class="title-bg">
      <img src="/assets/image/about-us/desktop/about-us_title_bg.png" alt="" />
      <div
        class="content-wrapper our-latest pageTitle pb-2"
        v-html="$t(`creatorPartners.title`)"
      ></div>
    </div>
    <div class="pressCoverage section-our-latest-creator-partner">
      <div class="content-wrapper">
        <section
          v-intersect="{
            options: {
              threshold: 1
            }
          }"
          class="section-title text-capitalize text-body-1 text-sm-h5 text-md-h4 surface--text mb-4 mb-md-8"
        >
          <transition name="scroll-x">
            <Carousel
              :items="1"
              :autoplay="false"
              :rewind="true"
              :dots="false"
              :nav="false"
              :loop="false"
            >
              <div class="brandsImg">
                <div class="d-flex align-center w-100">
                  <div class="column">
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners12.png"
                    />
                  </div>
                  <div class="column columnMove">
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners01.png"
                    />
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners02.png"
                    />
                  </div>
                  <div class="column columnMove2">
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners03.png"
                    />
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners04.png"
                    />
                  </div>
                  <div class="column">
                    <img
                      class=""
                      src="/assets/image/home/creatorPartners/home_creatorPartners05.png"
                    />
                  </div>
                  <div
                    @click="owlNext"
                    class="brandsMore left our-latest"
                    v-html="`More Brands >>`"
                  ></div>
                </div>
              </div>
              <div class="brandsImg">
                <div class="d-flex align-center">
                  <div class="column">
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners06.png"
                    />
                  </div>
                  <div class="column columnMove3">
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners07.png"
                    />
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners08.png"
                    />
                  </div>
                  <div class="column columnMove4">
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners09.png"
                    />
                    <img
                      src="/assets/image/home/creatorPartners/home_creatorPartners10.png"
                    />
                  </div>
                  <div class="column">
                    <img
                      class=""
                      src="/assets/image/home/creatorPartners/home_creatorPartners11.png"
                    />
                  </div>
                  <div
                    @click="owlNext"
                    class="brandsMore right our-latest"
                    v-html="`<< More Brands `"
                  ></div>
                </div>
              </div>
              <template slot="next"
                ><span id="owlNext" style="display: none" class="next"
                  >next</span
                ></template
              >
            </Carousel>
          </transition>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'SectionCreatorPartners',
    routeName: ROUTE_NAME,
    data: () => ({}),
    methods: {
      owlNext() {
        document.getElementById('owlNext').click();
      }
    }
  };
</script>

<style lang="scss"></style>
