<template>
  <div id="topPanel" class="top-panel mb-6 mb-sm-12">
    <!-- begin:: First Section  -->
    <section v-intersect="{
      handler: onIntersectFirstSection,
      options: {
        threshold: 1.0
      }
    }">
      <transition name="slide-fade-y">
        <div v-show="isShowFirstSection" class="top-panel__carousel-container">
          <video ref="video" muted autoplay loop playsinline class="carousel-video">
            <source src="assets/video/topBanner4.mp4" type="video/mp4" v-if="os == 'Mac OS' || os == 'iOS'" />
            <source src="assets/video/CookX_animation_2.webm" type="video/mp4" v-if="os != 'Mac OS' || os != 'iOS'" />
          </video>
          <div class="w-100 text-center slogan">
            {{ $t('topPanel.message2') }}
          </div>
          <div style="position: absolute; bottom: 0; width: 100%">
            <template v-for="(i, index) in appsIcons">
              <transition :key="index" name="slide-fade-y">
                <img v-show="isShowDownloadLink" :src="i.src" class="col col-3 cursor-pointer" :class="i.class"
                  style="width: 150px" @click="getAppsDownloadLink(i)" />
              </transition>
            </template>
          </div>
        </div>
      </transition>

      <v-card flat color="surface" class="d-flex align-center content-wrapper mb-6 mt-6">
        <transition name="slide-fade-y">
          <div v-show="isShowFirstSection" class="top-panel__message w-100 text-center secondary--text enter-delay-750ms"
            style="margin: 20px 0" v-html="$t(`topPanel.message`)"></div>
        </transition>
      </v-card>
      <transition name="slide-fade-y">
        <div v-show="isShowFirstSection" class="top-panel__message w-100 text-center enter-delay-2000ms"
          style="margin-bottom: 50px">
          <div class="content-wrapper">
            <div class="row">
              <div class="col-12">
                <video ref="video" muted autoplay loop playsinline controls disablePictureInPicture style="width: 100%">
                  <source src="assets/video/Senang_Aje.mp4" type="video/mp4" />
                </video>
                <!-- <iframe class="embedded-video-16-9"
                  src="https://www.youtube.com/embed/co68GcLnLjY?si=mZAGtiKIFuXzOFJL&amp;controls=0"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
    </section>
    <!-- end:: First Section -->

    <!-- begin:: Second Section -->
    <!-- <section
      v-intersect="{
        handler: onIntersectSecondSection,
        options: {
          threshold: 1.0
        }
      }"
    >
      <div class="content-wrapper">
        <v-row no-gutters justify="space-between">
          <template v-for="(i, index) in typesOfCookX">
            <transition :key="index" name="slide-fade-x">
              <v-col
                v-if="isShowSecondSection"
                cols="6"
                md="5"
                :class="
                  index === 0
                    ? `pr-4 pr-sm-6 ${isMobile ? 'enter-delay-2000ms' : ''}`
                    : `pl-4 pl-sm-6 ${
                        isMobile ? 'enter-delay-3s' : 'enter-delay-500ms'
                      }`
                "
              >
                <router-link
                  :to="{ name: routeName.ABOUT_COOKX }"
                  class="text-decoration-none types-of-cookx-link"
                >
                  <v-img
                    :src="i.src"
                    :height="typesOfCookXImageHeight"
                    class="types-of-cookx-link__image"
                  >
                    <div
                      class="types-of-cookx-link__text d-flex justify-center align-center h-100 py-12 py-sm-0 pa-sm-6 surface--text font-weight-bold text-body-1 text-sm-h5 text-center"
                    >
                      <div v-html="$t(`topPanel.${i.text}`)"></div>
                    </div>
                  </v-img>
                </router-link>
              </v-col>
            </transition>
          </template>
        </v-row>
      </div>
    </section> -->
    <!-- end:: Second Section -->
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/core/constants';
import { uiHelper } from '@/core/utils';
import { DEVICE_TYPE } from '@/core/constants/enums';
import constantsKey from '@/core/filters/constants-key.filter';
import {
  LOOKUP_GET_GLOBAL_SETTING,
  LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE
} from '@/core/store/lookup.module';
import Vue from 'vue'
import { Plugin } from 'vue-lazy-youtube-video'

Vue.use(Plugin)

export default {
  name: 'TopPanel',
  data: () => ({
    carousel: 0,
    routeName: ROUTE_NAME,
    isShowFirstSection: false,
    isShowSecondSection: false,
    isShowDownloadLink: true,
    os: '',
    typesOfCookX: [
      {
        text: 'chillnCook',
        src: '/assets/image/home_chillncook.png'
      },
      {
        text: 'gettyEat',
        src: '/assets/image/home_gettyeat.png'
      }
    ],
    appsIcons: [
      {
        src: '/assets/image/google-play-store.png',
        key: DEVICE_TYPE.ANDROID,
        class: ' enter-delay-1000ms'
      },
      {
        src: '/assets/image/apple-app-store.png',
        key: DEVICE_TYPE.IOS,
        class: ' enter-delay-2000ms'
      },
      {
        src: '/assets/image/huawei-app-gallery.png',
        key: DEVICE_TYPE.HUAWEI,
        class: ' enter-delay-3s'
      }
    ]
  }),
  computed: {
    globalSettingComplete() {
      return this.$store.state.lookup.globalSetting.complete;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    typesOfCookXImageHeight() {
      let height = '280px';

      if (this.$vuetify.breakpoint.mdAndUp) {
        height = '100%';
      } else if (this.$vuetify.breakpoint.smOnly) {
        height = '250px';
      } else if (this.$vuetify.breakpoint.xsOnly) {
        height = '100%';
      }

      return height;
    },
    topPanelMessage() {
      let currentScreenWidth = this.$vuetify.breakpoint.width;
      let message = this.isMobile ? 'desktopMessage' : 'mobileMessage';

      if (currentScreenWidth < 400) {
        message = 'smallerMobileMessage';
      }

      return message;
    }
  },
  watch: {
    globalSettingComplete() {
      let response = this.$store.state.lookup.globalSetting;

      if (response.complete) {
        this.globalSettingCompleteAction(response);
      }
    }
  },
  mounted() {
    this.getOS();
  },
  methods: {
    globalSettingCompleteAction(response) {
      if (response.code == 0) {
        uiHelper.openPage(response.data.url, '_self');
      } else {
        console.log(response);
      }

      this.initialGetGlobalSettingState();
    },
    getAppsDownloadLink(value) {
      let platformKey = constantsKey('deviceType', value.key);
      let data = {
        platform: platformKey
      };

      this.getGlobalSetting(data);
    },
    getGlobalSetting(data) {
      this.$store.dispatch(LOOKUP_GET_GLOBAL_SETTING, { data });
    },
    onIntersectSecondSection(entries, observer, isIntersecting) {
      var rootContainer = document.querySelector('#topPanel');
      var rootContainerBoundingClientRect =
        rootContainer.getBoundingClientRect();

      if (this.isMobile) {
        if (isIntersecting) this.isShowSecondSection = true;
      } else {
        if (this.isShowFirstSection && isIntersecting) {
          if (0 > rootContainerBoundingClientRect.y) {
            this.isShowSecondSection = true;
          }
        }
      }
    },
    onIntersectFirstSection(entries, observer, isIntersecting) {
      if (isIntersecting) this.isShowFirstSection = true;
    },
    getOS() {
      var userAgent = window.navigator.userAgent,
        platform =
          window.navigator?.userAgentData?.platform ??
          window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        this.os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        this.os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        this.os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        this.os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        this.os = 'Linux';
      }
    },
    initialGetGlobalSettingState() {
      this.$store.dispatch(LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE);
    }
  }
};
</script>

<style lang="scss">
.embedded-video-16-9 {
  width: 90vw;
  height: 50.625vw;
  /* 90*9/16 */
  // margin-left: 5vw;
  // margin-right: 5vw;
}

@media (min-width: 893px) {
  .embedded-video-16-9 {
    width: 60vw;
    height: 25.3125vw;
    /* 45*9/16 */
    margin-left: 2vw;
    margin-right: 2vw
  }
}
</style>
