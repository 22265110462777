<template>
  <section v-intersect="onIntersectSecondSection">
    <transition name="slide-fade-y">
      <div
        v-show="isShowSecondSection"
        class="section-title text-body-1 text-sm-h5 text-md-h4 surface--text mb-4 mb-md-8"
      >
        <div class="title-bg">
          <img
            src="/assets/image/about-us/desktop/about-us_title_bg.png"
            alt=""
          />
          <div
            class="content-wrapper our-latest pageTitle pb-2"
            v-html="$t(`howToJoin.title`)"
          ></div>
        </div>
        <div class="section-wrapper section-our-latest-creator-partner">
          <div class="content-wrapper">
            <transition name="scroll-x">
              <div
                class="d-lg-flex align-start justify-content-between"
                :class="isMobile ? '' : ''"
              >
                <template v-for="(i, index) in howTojoin">
                  <div :key="index" class="joinDetail px-5">
                    <div class="joinDetailImg our-latest">
                      <img :src="i.imageSrc" alt="" />
                      <div class="top-left" v-html="index + 1 + '.'"></div>
                    </div>
                    <p
                      class="joinDetailTitle our-latest"
                      v-html="$t(`howToJoin.steps[${index}].desktopTitle`)"
                    ></p>
                    <div
                      class="joinDetailDescription"
                      v-html="$t(`howToJoin.steps[${index}].description`)"
                    ></div>
                    <div
                      class="joinDetailDescription our-latest"
                      v-html="$t(`howToJoin.steps[${index}].description2`)"
                    ></div>
                  </div>
                </template>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
  export default {
    name: 'SectionHowToJoin',
    data: () => ({
      isShowSecondSection: false,
      howTojoin: [
        {
          imageSrc: 'assets/image/home/ToJoin/home_howtojoin01.png'
        },
        {
          imageSrc: 'assets/image/home/ToJoin/home_howtojoin02.png'
        },
        {
          imageSrc: 'assets/image/home/ToJoin/home_howtojoin03.png'
        }
      ]
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    methods: {
      onIntersectSecondSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowSecondSection = true;
        // this.isShowSecondSection = isIntersecting ? true : false;
      }
    }
  };
</script>

<style lang="scss"></style>
