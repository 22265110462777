<template>
  <section v-intersect="onIntersectPressCoverageSection">
    <transition name="slide-fade-y">
      <div class="title-bg" v-show="isShowPressCoverage">
        <img
          src="/assets/image/about-us/desktop/about-us_title_bg.png"
          alt=""
        />
        <div
          class="content-wrapper our-latest pageTitle pb-2"
          v-html="$t(`pressCoverage.title`)"
        ></div>
      </div>
    </transition>
    <transition name="slide-fade-y">
      <div
        v-show="isShowPressCoverage"
        class="pressCoverage section-our-latest-creator-partner"
      >
        <div class="content-wrapper">
          <section
            v-intersect="{
              options: {
                threshold: 1
              }
            }"
            class="section-title text-capitalize text-body-1 text-sm-h5 text-md-h4 surface--text mb-4 mb-md-8"
          >
            <transition name="scroll-x">
              <div class="d-flex justify-space-center flex-column">
                <div
                  class="d-flex align-items-center justify-space-between flex-wrap"
                >
                  <template v-for="(i, index) in pressCoverage.data">
                    <router-link
                      :key="index"
                      :to="{
                        name: 'press-coverage-detail',
                        params: { id: i.id }
                      }"
                      class="pressCoverImg d-flex align-items-center justify-space-between flex-wrap"
                    >
                      <img
                        class="px-lg-5 py-5"
                        :src="i.logo"
                        :alt="index + 1"
                        width="100%"
                      />
                    </router-link>
                  </template>
                </div>
                <div class="tow-button link">
                  <router-link
                    :to="{ name: 'press-coverage' }"
                    class="topbtn main--btn"
                  >
                    MORE
                  </router-link>
                </div>
              </div>
            </transition>
          </section>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
  import ROUTE_NAME from '@/core/constants';
  import { apiService } from '@/core/services';

  export default {
    name: 'SectionPressCoverage',
    routeName: ROUTE_NAME,
    data: () => ({
      isShowPressCoverage: false,
      pressCoverage: []
    }),
    created() {
      this.getPressCoverageImg();
    },
    methods: {
      onIntersectPressCoverageSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowPressCoverage = true;
      },
      getPressCoverageImg() {
        let result;
        apiService()
          .get('/api/v1/press-coverage/show/logo')
          .then((response) => {
            result = response;
            this.pressCoverage = result;
          })
          .catch((error) => {
            result = error;
          });
      }
    }
  };
</script>

<style lang="scss">
  .pressCoverage {
    .tow-button {
      width: unset;
      font-size: 0.875rem;
      text-align: center;
    }
    .tow-button.link {
    }
  }
</style>
