var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-wrapper section-apps-download pb-0 pt-0",class:{ '': !_vm.isMobile }},[_c('div',{staticClass:"secondary part-3 d-flex justify-center align-center"},[_c('div',{staticClass:"content-wrapper vueSlick-parent"},[_c('section',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersectFourSection,
          options: {
            threshold: 1
          }
        }),expression:"{\n          handler: onIntersectFourSection,\n          options: {\n            threshold: 1\n          }\n        }"}]},[_c('transition',{attrs:{"name":"slide-fade-y"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowFourSection),expression:"isShowFourSection"}],staticClass:"pb-12 pt-12 our-latest"},[_vm._v(" Our Latest ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowFourSection),expression:"isShowFourSection"}],staticClass:"vueSlick-wrapper"},[_c('VueSlickCarousel',{attrs:{"arrows":true,"dots":false,"centerMode":true,"infinite":true,"slidesToShow":_vm.isMobile ? 1 : 3}},[_c('div',{staticClass:"vs-img"},[_c('img',{attrs:{"src":"/assets/image/Instagram/1Giveaway.jpeg"}})]),_c('div',{staticClass:"vs-img"},[_c('img',{attrs:{"src":"/assets/image/Instagram/2Dewan.png"}})]),_c('div',{staticClass:"vs-img"},[_c('img',{attrs:{"src":"/assets/image/Instagram/3eggtart.png"}})]),_c('div',{staticClass:"vs-img"},[_c('img',{attrs:{"src":"/assets/image/Instagram/4Beacon.png"}})]),_c('div',{staticClass:"vs-img"},[_c('img',{attrs:{"src":"/assets/image/Instagram/5orderdelivery.png"}})])])],1),_c('div',{staticStyle:{"clear":"both"}})],1)])]),_c('div',{staticClass:"secondary part-3 d-flex justify-center align-center"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersectFourThreeSection,
          options: {
            threshold: 1
          }
        }),expression:"{\n          handler: onIntersectFourThreeSection,\n          options: {\n            threshold: 1\n          }\n        }"}]},[_c('transition',{attrs:{"name":"slide-fade-y"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowFourThreeSection),expression:"isShowFourThreeSection"}],staticClass:"pb-12 pt-12 social-btn-lists"},[_c('div',{staticClass:"d-flex"},[_vm._l((_vm.socialMedia),function(i,index){return [(_vm.platformInfo.data[i.logo])?_c('a',{key:index,attrs:{"href":_vm.platformInfo.data[i.logo],"target":"_blank"}},[_c('img',{staticClass:"mr-4 social-media-icon",attrs:{"src":`/assets/image/${i.logo}.png`}}),_c('span',[_vm._v(_vm._s(i.text))])]):_vm._e()]})],2)])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }