<template>
  <div
    class="section-wrapper section-partner-with-us h-100 pb-0 pt-0"
    :class="{ '': !isMobile }"
  >
    <div class="content-wrapper section-partner-width-us__image-wrapper">
      <transition name="slide-fade-y">
        <v-img
          v-show="isShowImages"
          src="/assets/image/home_faq.png"
          :max-width="faqNCareerImageMaxWidth"
          class="section-partner-width-us__faq-image"
        ></v-img>
      </transition>

      <div class="d-flex justify-end">
        <transition name="slide-fade-y">
          <v-img
            v-show="isShowImages"
            src="/assets/image/home_career.png"
            :max-width="faqNCareerImageMaxWidth"
            class="section-partner-width-us__career-image"
          ></v-img>
        </transition>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent content-class="qrdialog">
      <v-card>
        <v-card-text>
          <img
            class="closebtn"
            src="/assets/image/close-btn.png"
            @click="dialog = false"
          />
          <div class="downloadtext">
            Point your camera at the<br />QR code to download
          </div>
          <div class="downloadbrandname">{{ $t('label.brandName') }}</div>
          <qr-code
            :size="qrCodeSize"
            :text="qrCodeURL"
            class="qr-code"
          ></qr-code>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <div class="secondary part-2 d-flex justify-center align-center">
      <transition-group
        v-intersect="{
          handler: onIntersectSecondSection,
          options: {
            threshold: 1.0
          }
        }"
        name="slide-fade-y"
        tag="div"
        class="pb-12 mb-md-8 faq-part"
      >
        <template v-for="(i, index) in linkList">
          <router-link
            v-if="isShowLinkList"
            :key="index"
            :to="{ name: i.routeName }"
            class="d-block font-weight-bold text-body-1 text-sm-h5 text-md-h4 surface--text text-decoration-none text-uppercase text-center mb-4 mb-sm-6 mb-md-4"
            :class="`enter-delay-${index + 1}s`"
          >
            {{ $t(`label.${i.text}`) }}
          </router-link>
        </template>
      </transition-group>
    </div>
    <div class="secondary part-5 d-flex justify-center align-center">
      <section
        v-intersect="{
          handler: onIntersectFifthSection,
          options: {
            threshold: 1.0
          }
        }"
      >
        <transition name="slide-fade-y">
          <div class="getustitle" v-show="isShowFifthSection">
            <v-row class="no-gutters">
              <v-col cols="12" sm="6" class="pa-md-6 get-us-here"
                >Get Us Here</v-col
              >
              <v-col cols="12" sm="6" class="pa-md-6" style="text-align: right">
                <a
                  class="qrbtn"
                  href="javascript:void(0)"
                  @click.prevent="openqrcode"
                >
                  <img src="/assets/image/qrbtn.png" />
                  <span>QR CODE</span></a
                >
              </v-col>
            </v-row>
          </div>
        </transition>
      </section>
    </div>
    <div class="secondary part-3 d-flex justify-center align-center">
      <section
        v-intersect="{
          handler: onIntersectThirdSection,
          options: {
            threshold: 0.4
          }
        }"
      >
        <transition name="slide-fade-y">
          <div class="enter-delay-500ms" v-show="isShowThirdSection">
            <img
              src="/assets/image/home_download-apps.png"
              class="download-app-img"
            />
          </div>
        </transition>
      </section>
    </div>
    <div class="secondary part-4 d-flex justify-center align-center pb-12">
      <div class="download-link-row">
        <v-row
          no-gutters
          v-intersect="{
            handler: onIntersectDownloadLink,
            options: {
              threshold: 1.0
            }
          }"
        >
          <template v-for="(i, index) in appsIcons">
            <v-col
              :key="index"
              cols="4"
              class="pa-md-6"
              :class="[
                { 'pr-2': index == 0 },
                { 'px-1': index == 1 },
                { 'pl-2': index == 2 }
              ]"
            >
              <transition name="slide-fade-y">
                <img
                  v-show="isShowDownloadLink"
                  :src="i.src"
                  class="max-w-100 cursor-pointer"
                  :class="`enter-delay-${index + 1}s`"
                  @click="getAppsDownloadLink(i)"
                />
              </transition>
            </v-col>
          </template>
        </v-row>
        <v-row class="no-gutters">
          <v-col cols="12" class="text-center download-now">DOWNLOAD NOW</v-col>
        </v-row>
      </div>
    </div> -->
  </div>
</template>

<script>
  import { ROUTE_NAME } from '@/core/constants';
  import constantsKey from '@/core/filters/constants-key.filter';
  import { DEVICE_TYPE } from '@/core/constants/enums';
  import { LOOKUP_GET_GLOBAL_SETTING } from '@/core/store/lookup.module';

  export default {
    name: 'SectionPartnerWithUs',
    data: () => ({
      routeName: ROUTE_NAME,
      linkList: [
        {
          text: 'faqs',
          routeName: ROUTE_NAME.FAQ
        },
        {
          text: 'contactUs',
          routeName: ROUTE_NAME.CONTACT_US
        },
        {
          text: 'termsOfUse',
          routeName: ROUTE_NAME.TERMS_OF_USE
        },
        {
          text: 'privacyPolicy',
          routeName: ROUTE_NAME.PRIVACY_POLICY
        }
        // {
        //   text: 'careers',
        //   routeName: ROUTE_NAME.CAREERS
        // }
      ],
      appsIcons: [
        {
          src: '/assets/image/google-play-store.png',
          key: DEVICE_TYPE.ANDROID
        },
        {
          src: '/assets/image/apple-app-store.png',
          key: DEVICE_TYPE.IOS
        },
        {
          src: '/assets/image/huawei-app-gallery.png',
          key: DEVICE_TYPE.HUAWEI
        }
      ],
      isShowFirstSection: false,
      isShowThirdSection: false,
      isShowFifthSection: false,
      isShowLinkList: false,
      isShowDownloadLink: false,
      isShowImages: false,
      dialog: false
    }),
    computed: {
      faqNCareerImageMaxWidth() {
        let width = '200';

        if (this.$vuetify.breakpoint.mdAndUp) {
          width = '200';
        } else if (this.$vuetify.breakpoint.smOnly) {
          width = '120';
        } else if (this.$vuetify.breakpoint.xsOnly) {
          width = '80';
        }

        return width;
      },
      qrCodeSize() {
        let size = 130;

        if (this.$vuetify.breakpoint.lgAndUp) {
          size = 130;
        } else if (this.$vuetify.breakpoint.mdOnly) {
          size = 100;
        } else if (this.$vuetify.breakpoint.smAndDown) {
          size = 80;
        }

        return size;
      },
      qrCodeURL() {
        let location = window.location;

        return `${location.protocol}${location.hostname}/${ROUTE_NAME.QR_LINK}`;
      },
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    methods: {
      getAppsDownloadLink(value) {
        let platformKey = constantsKey('deviceType', value.key);
        let data = {
          platform: platformKey
        };

        this.getGlobalSetting(data);
      },
      getGlobalSetting(data) {
        this.$store.dispatch(LOOKUP_GET_GLOBAL_SETTING, { data });
      },
      onIntersectSecondSection(entries, observer, isIntersecting) {
        if (isIntersecting) {
          this.isShowLinkList = true;
          this.isShowImages = true;
        }
      },
      onIntersectFirstSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFirstSection = true;
      },
      onIntersectThirdSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowThirdSection = true;
      },
      onIntersectFifthSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFifthSection = true;
      },
      onIntersectDownloadLink(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowDownloadLink = true;
      },
      openqrcode() {
        this.dialog = true;
      }
    }
  };
</script>

<style lang="scss">
  .section-partner-with-us {
    .part-1 {
      min-height: 28rem;
      .section-title {
        letter-spacing: 15px !important;
      }
      .section-description {
        line-height: 2rem !important;
        letter-spacing: 3px !important;
        max-width: 53%;
        margin: 0 auto 0 auto;
      }
    }
    .part-2 {
      min-height: 40rem;
    }
    .part-4 .download-link-row {
      width: 730px;
    }
    .part-5 section {
      width: 100%;
    }
    .getustitle {
      width: 730px;
      margin: auto;
    }
    .section-partner-width-us__image-wrapper {
      position: relative;
      .section-partner-width-us__faq-image {
        position: absolute;
        margin-top: 250px;
        transition-delay: 1s;
      }
      .section-partner-width-us__career-image {
        position: absolute;
        margin-top: 100px;
      }
    }
  }

  @media (max-width: 959px) {
    .section-partner-with-us {
      .part-1 {
        min-height: 22rem;
        .section-title {
          letter-spacing: 12px !important;
        }
        .section-description {
          line-height: 1rem !important;
          letter-spacing: 1px !important;
          max-width: 70%;
        }
      }
      .part-2 {
        min-height: 20rem;
      }
      .section-partner-width-us__image-wrapper {
        .section-partner-width-us__faq-image {
          margin-top: 100px;
        }
        .section-partner-width-us__career-image {
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: 730px) {
    .section-partner-with-us {
      .part-4 .download-link-row,
      .getustitle {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
      .download-app-img {
        width: 100%;
      }
    }
  }
  @media (max-width: 599px) {
    .section-partner-with-us {
      .part-1 {
        min-height: 14rem;
        .section-title {
          letter-spacing: 9px !important;
        }
        .section-description {
          max-width: 100%;
          letter-spacing: 0.0333333333em !important;
        }
      }
      .part-2 {
        min-height: 16rem;
      }
      .section-partner-width-us__image-wrapper {
        .section-partner-width-us__faq-image {
          margin-top: -28px;
        }
        .section-partner-width-us__career-image {
          margin-top: 16px;
        }
      }
    }
  }
</style>
