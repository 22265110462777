var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersectPressCoverageSection),expression:"onIntersectPressCoverageSection"}]},[_c('transition',{attrs:{"name":"slide-fade-y"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowPressCoverage),expression:"isShowPressCoverage"}],staticClass:"title-bg"},[_c('img',{attrs:{"src":"/assets/image/about-us/desktop/about-us_title_bg.png","alt":""}}),_c('div',{staticClass:"content-wrapper our-latest pageTitle pb-2",domProps:{"innerHTML":_vm._s(_vm.$t(`pressCoverage.title`))}})])]),_c('transition',{attrs:{"name":"slide-fade-y"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowPressCoverage),expression:"isShowPressCoverage"}],staticClass:"pressCoverage section-our-latest-creator-partner"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            options: {
              threshold: 1
            }
          }),expression:"{\n            options: {\n              threshold: 1\n            }\n          }"}],staticClass:"section-title text-capitalize text-body-1 text-sm-h5 text-md-h4 surface--text mb-4 mb-md-8"},[_c('transition',{attrs:{"name":"scroll-x"}},[_c('div',{staticClass:"d-flex justify-space-center flex-column"},[_c('div',{staticClass:"d-flex align-items-center justify-space-between flex-wrap"},[_vm._l((_vm.pressCoverage.data),function(i,index){return [_c('router-link',{key:index,staticClass:"pressCoverImg d-flex align-items-center justify-space-between flex-wrap",attrs:{"to":{
                      name: 'press-coverage-detail',
                      params: { id: i.id }
                    }}},[_c('img',{staticClass:"px-lg-5 py-5",attrs:{"src":i.logo,"alt":index + 1,"width":"100%"}})])]})],2),_c('div',{staticClass:"tow-button link"},[_c('router-link',{staticClass:"topbtn main--btn",attrs:{"to":{ name: 'press-coverage' }}},[_vm._v(" MORE ")])],1)])])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }