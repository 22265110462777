<template>
  <div
    class="section-wrapper section-apps-download pb-0 pt-0"
    :class="{ '': !isMobile }"
  >
    <div class="secondary part-3 d-flex justify-center align-center">
      <div class="content-wrapper vueSlick-parent">
        <section
          v-intersect="{
            handler: onIntersectFourSection,
            options: {
              threshold: 1
            }
          }"
        >
          <transition name="slide-fade-y">
            <div class="pb-12 pt-12 our-latest" v-show="isShowFourSection">
              Our Latest
            </div>
          </transition>
          <div class="vueSlick-wrapper" v-show="isShowFourSection">
            <VueSlickCarousel
              :arrows="true"
              :dots="false"
              :centerMode="true"
              :infinite="true"
              :slidesToShow="isMobile ? 1 : 3"
            >
              <div class="vs-img">
                <img src="/assets/image/Instagram/1Giveaway.jpeg" />
              </div>
              <div class="vs-img">
                <img src="/assets/image/Instagram/2Dewan.png" />
              </div>
              <div class="vs-img">
                <img src="/assets/image/Instagram/3eggtart.png" />
              </div>
              <div class="vs-img">
                <img src="/assets/image/Instagram/4Beacon.png" />
              </div>
              <div class="vs-img">
                <img src="/assets/image/Instagram/5orderdelivery.png" />
              </div>
            </VueSlickCarousel>
          </div>
          <div style="clear: both"></div>
        </section>
      </div>
    </div>

    <div class="secondary part-3 d-flex justify-center align-center">
      <div class="content-wrapper">
        <section
          v-intersect="{
            handler: onIntersectFourThreeSection,
            options: {
              threshold: 1
            }
          }"
        >
          <transition name="slide-fade-y">
            <div
              class="pb-12 pt-12 social-btn-lists"
              v-show="isShowFourThreeSection"
            >
              <div class="d-flex">
                <template v-for="(i, index) in socialMedia">
                  <a
                    :key="index"
                    v-if="platformInfo.data[i.logo]"
                    :href="platformInfo.data[i.logo]"
                    target="_blank"
                  >
                    <img
                      :src="`/assets/image/${i.logo}.png`"
                      class="mr-4 social-media-icon"
                    />
                    <span>{{ i.text }}</span>
                  </a>
                </template>
              </div>
            </div>
          </transition>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import { uiHelper } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';
  import constantsKey from '@/core/filters/constants-key.filter';
  import { DEVICE_TYPE } from '@/core/constants/enums';
  import {
    LOOKUP_GET_GLOBAL_SETTING,
    LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE
  } from '@/core/store/lookup.module';
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

  export default {
    name: 'SectionAppsDownload',
    components: { VueSlickCarousel },
    data: () => ({
      appsIcons: [
        {
          src: '/assets/image/google-play-store.png',
          key: DEVICE_TYPE.ANDROID
        },
        {
          src: '/assets/image/apple-app-store.png',
          key: DEVICE_TYPE.IOS
        },
        {
          src: '/assets/image/huawei-app-gallery.png',
          key: DEVICE_TYPE.HUAWEI
        }
      ],
      socialMedia: [
        {
          logo: 'instagram',
          text: 'cookxasia'
        },
        {
          logo: 'facebook',
          text: 'Cookx Asia'
        }
      ],
      isShowTitle: false,
      isShowDownloadLink: false,
      isShowFourSection: false,
      isShowFourTwoSection: false,
      isShowFourThreeSection: false
    }),
    computed: {
      globalSettingComplete() {
        return this.$store.state.lookup.globalSetting.complete;
      },
      qrCodeSize() {
        let size = 130;

        if (this.$vuetify.breakpoint.lgAndUp) {
          size = 130;
        } else if (this.$vuetify.breakpoint.mdOnly) {
          size = 100;
        } else if (this.$vuetify.breakpoint.smAndDown) {
          size = 80;
        }

        return size;
      },
      qrCodeURL() {
        let location = window.location;

        return `${location.protocol}${location.hostname}/${ROUTE_NAME.QR_LINK}`;
      },
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      platformInfo() {
        return this.$store.state.platformInfo.platformInfo;
      }
    },
    watch: {
      globalSettingComplete() {
        let response = this.$store.state.lookup.globalSetting;

        if (response.complete) {
          this.globalSettingCompleteAction(response);
        }
      }
    },
    methods: {
      onIntersectDownloadLink(entries, observer, isIntersecting) {
        if (isIntersecting) {
          this.isShowDownloadLink = true;
        }
      },
      onIntersectTitle(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowTitle = true;
      },
      globalSettingCompleteAction(response) {
        if (response.code == 0) {
          uiHelper.openPage(response.data.url, '_self');
        } else {
          console.log(response);
        }

        this.initialGetGlobalSettingState();
      },
      getAppsDownloadLink(value) {
        let platformKey = constantsKey('deviceType', value.key);
        let data = {
          platform: platformKey
        };

        this.getGlobalSetting(data);
      },
      getGlobalSetting(data) {
        this.$store.dispatch(LOOKUP_GET_GLOBAL_SETTING, { data });
      },
      initialGetGlobalSettingState() {
        this.$store.dispatch(LOOKUP_INITIAL_GET_GLOBAL_SETTING_STATE);
      },
      onIntersectFourSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFourSection = true;
      },
      onIntersectFourTwoSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFourTwoSection = true;
      },
      onIntersectFourThreeSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFourThreeSection = true;
      }
    }
  };
</script>

<style lang="scss">
  .section-apps-download {
    .content-wrapper {
      width: 80% !important;
    }
    .section-title {
      line-height: 35px;
      letter-spacing: 8px !important;
    }
    .section-subtitle {
      writing-mode: vertical-rl;
      transform: scale(-1);
    }
    .qr-code {
      border: 10px solid white;
    }
    .download-link-row {
      min-height: 140px;
    }
    .part-3 .social-btn-lists {
      width: 700px;
      margin: auto;
    }
    .part-3 section a {
      width: 100%;
      text-decoration: none;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
    }
    .part-3 .social-media-icon {
      height: 70px;
    }
    .content-wrapper-full {
      width: 100%;
    }
  }

  @media (max-width: 959px) {
    .section-apps-download {
      .section-title {
        line-height: 30px;
        letter-spacing: 5px !important;
      }
      .qr-code {
        border: 5px solid white;
      }
      .download-link-row {
        min-height: 0px;
      }
      .part-3 .social-btn-lists {
        width: 100%;
        margin: auto;
      }
    }
  }
  @media (max-width: 700px) {
    .section-apps-download {
      .part-3 .content-wrapper {
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        width: 100% !important;
      }
    }
  }
  @media (max-width: 599px) {
    .section-apps-download {
      .part-3 .content-wrapper {
        margin: 0;
        overflow: hidden;
        width: 100% !important;
      }
      .section-title {
        line-height: 22px;
        letter-spacing: 3px !important;
      }
      .part-3 .social-media-icon {
        height: 50px;
      }
      .part-3 section a {
        font-size: 15px;
      }
    }
  }
</style>
