<template>
  <div>
    <!-- Top Panel Start -->
    <TopPanel></TopPanel>
    <!-- Top Panel End -->

    <!-- Section Wondering How To Get Started Start-->
    <SectionHowToJoin></SectionHowToJoin>
    <!-- Section Wondering How To Get Started End-->

    <!-- Section Apps Download Start -->
    <SectionAppsDownload></SectionAppsDownload>
    <!-- Section Apps Download End -->

    <!-- Section Partner With Us Start -->
    <SectionPartnerWithUs></SectionPartnerWithUs>
    <!-- Section Partner With Us End -->

    <!-- Section Press Coverage Start -->
    <SectionPressCoverage></SectionPressCoverage>
    <!-- Section Press Coverage End -->

    <!-- Section Creator Partners Start -->
    <SectionCreatorPartners></SectionCreatorPartners>
    <!-- Section Creator Partners End -->
  </div>
</template>

<script>
  import TopPanel from '@/views/components/home/TopPanel';
  import SectionHowToJoin from '@/views/components/home/SectionHowToJoin';
  import SectionAppsDownload from '@/views/components/home/SectionAppsDownload';
  import SectionPartnerWithUs from '@/views/components/home/SectionPartnerWithUs';
  import SectionPressCoverage from '@/views/components/home/SectionPressCoverage';
  import SectionCreatorPartners from '@/views/components/home/SectionCreatorPartners';

  export default {
    name: 'Home',
    components: {
      TopPanel,
      SectionHowToJoin,
      SectionAppsDownload,
      SectionPartnerWithUs,
      SectionPressCoverage,
      SectionCreatorPartners
    }
  };
</script>
